//Export Button
import LoadingSpinner from "../../Common/LoadingSpinner";// Make sure to import your LoadingSpinner component here

const ExportButton = ({ onClick, isExporting }) => {
  return (
    <button
      type="button"
      disabled={isExporting}
      onClick={onClick}
      className="dds__button dds__button--primary dds__ml-2 dds__mb-3"
    >
      {!isExporting && (
        <>
          Export
          <span className="dds__icon" style={{ marginLeft: '10px' }}>
            <i className="dds__icon--arrow-export"></i>
          </span>
        </>
      )}
      {isExporting && (
        <>
          <LoadingSpinner size='sm' />
          <span className="dds__ml-2">Exporting</span>
        </>
      )}
    </button>
  );
};

export default ExportButton;
