import { useCustomQuery } from "../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../react-query/constants";
import { fetchServiceAccountOrgConfig } from "../API";

const useServiceAccountOrgData = (enabled: boolean = true) => {
	return useCustomQuery(
		[queryKeys.serviceaccountadmin],
		fetchServiceAccountOrgConfig(),
		{
			//Using unauth api for getting the user's role for now change it with service accounts api
			select: data => data.data,
		}
	);
};

export default useServiceAccountOrgData;
