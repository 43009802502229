import React, { useEffect, useRef, useState } from "react";
import { Accordion } from "@dds/components";
import LoadingSpinner from "../../Common/LoadingSpinner";
import { useSearch } from "../Search/useSearch";
import DDSSearch from "../Search/DDSSearch";
import useFuzzySearch from "../Search/useFuzzySearch";
import AccordianWrapper from "./Wrappers/AccordianWrapper";
import AccordianBodyWrapper from "./Wrappers/AccordianBodyWrapper";
import AccordianHeadingWrapper from "./Wrappers/AccordianHeadingWrapper";
import { cardColumns } from "../constants";
import { CardTableStyle } from "../CardTable/Styles";
import { Pagination } from "./AccordionPagination"
import ExportButton from "./AccordionExportButton"


interface IDDSAccordianProps {
    data: any[];
    isError: boolean;
    isLoading: boolean;
    columns: cardColumns;
    enableControls?: boolean;
    totalElements: number;
    labels?: {
        expandLabel: string;
        collapseLabel: string;
    };
    accordianID: string;
    sideWayTable?: boolean;
    //serviceaccounts used parrameters
    pageSize?: number;
    isExporting?: boolean;//Prop added to get the loading spinner enabled and export button disabled while data is being downloaded in CSV file
    onClick?: () => void;//Prop added to handle on click event of Export Button
    enableExportButton?: boolean;//Added Props for Enabling and Disabling Export Button for Accordian
    enablePagination?: boolean; //Added Props for Enabling and Disabling Pagination for Accordian
    enableSearch?: boolean;//Added Props for Enabling and Disabling Search for Accordian
    onlyShowDomainManagerServiceAccounts?: boolean; // Only show the domain manager service accounts

}

const DDSAccordian: React.FC<IDDSAccordianProps> = ({
    data,
    isError,
    isLoading,
    columns,
    enableControls = true,
    totalElements,
    labels = {
        expandLabel: "Expand All",
        collapseLabel: "Collapse All",
    },
    accordianID,
    sideWayTable = true,
    //serviceaccounts used parrameters
    isExporting,
    onClick,
    enableSearch = true,//By default Search is enabled
    enableExportButton = false,//By default Export Button is disabled
    enablePagination = false,//By default Pagination is disabled
    onlyShowDomainManagerServiceAccounts = false,  // Only show the domain manager service accounts


}) => {
    const { searchText, setSearchText, searchRef } = useSearch();
    const filteredData = useFuzzySearch(data, searchText, columns);

    const accordianInstanceRef = useRef<any>(null);
    const accordianRef = useRef<HTMLDivElement>(null);

    const headerColumns = columns.filter(col => !col.hidden).filter(col => !col.visible);
    const bodyColumns = columns.filter(col => !col.hidden).filter(col => col.visible);

    useEffect(() => {
        if (filteredData.length === 0) return;

        accordianInstanceRef.current?.dispose?.();
        accordianInstanceRef.current = new Accordion(accordianRef.current);
        if (searchText.length === 0)
            accordianInstanceRef.current?.expand?.(0);
        () => {
            accordianInstanceRef.current?.dispose?.();
        };
    }, [searchText, filteredData]);

    if (isLoading)
        return (
            <div className="dds__mt-5">
                <LoadingSpinner size="xl" />
            </div>
        );

    //Service accounts card used constant data
    const [pageSize, setPageSize] = useState<number>(10); // Number of items per page
    const [pageIndex, setPageIndex] = useState(0); // Current page index
    const pageCount = Math.ceil(filteredData.length / pageSize);

    const paginatedData = filteredData.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);//Filtering the data to show only 10 rows per page

    const canPreviousPage = pageIndex > 0;//Logic to check if the previous button is enabled or not
    const canNextPage = pageIndex < pageCount - 1;//Logic to check if the next button is enabled or not

    const previousPage = () => {//Logic to change the page index when previous button is clicked
        if (canPreviousPage) {
            setPageIndex(prevPageIndex => prevPageIndex - 1);
        }
    };

    const nextPage = () => {//Logic to change the page index when next button is clicked
        if (canNextPage) {
            setPageIndex(prevPageIndex => prevPageIndex + 1);
        }
    };


    return (
        <div>
            {!isError && (
                <>
                    {/* Search component */}
                    {enableSearch && (// Check if the search is enabled
                        <div className="dds__d-flex dds__align-items-center">
                            <DDSSearch searchRef={searchRef} searchText={searchText} setSearchText={setSearchText} />
                            <div className="dds__ml-2"></div> {/* Spacer div */}
                            {enableExportButton && ( // Check if the export button is enabled
                                <ExportButton
                                    onClick={onClick}
                                    isExporting={isExporting}
                                />
                            )}
                        </div>
                    )}

                    {/* Accordian Wrapper */}
                    <AccordianWrapper
                        labels={labels}
                        totalElements={totalElements}
                        enableControls={enableControls && data.length > 0}
                        elementRef={accordianRef}
                    >
                        {(enablePagination ? paginatedData : filteredData).map((record, recordIndex) => {  //If pagination is enabled show paginated data else show filtered data
                            const triggerId = `trigger-${accordianID}-item${recordIndex}`;
                            const contentId = `content-${accordianID}-item${recordIndex}`;
                            const CellComponent = ({ content }) => {
                                const htmlContent = Array.isArray(content) ? content.join(' ').replace(/\n/g, '<br />') : content.replace(/\n/g, '<br />');
                                return (
                                    <p
                                        className="dds__body-2"
                                        style={{ wordBreak: 'break-all', color: '#0E0E0E' }}
                                        dangerouslySetInnerHTML={{ __html: htmlContent }}
                                    />
                                )
                            }
                            return (
                                <div
                                    className="dds__accordion__item"
                                    key={recordIndex}
                                >
                                    <AccordianHeadingWrapper alert={record['alert']} triggerId={triggerId} contentId={contentId}>
                                        {/* Extra div to counteract default space between property w/o modifying styles */}
                                        <div>
                                            {headerColumns
                                                .map((col, index, filteredColumns) => {
                                                    const CellComponent = col.Cell;
                                                    const value = record[col.accessor];
                                                    const separator =
                                                        index !== filteredColumns.length - 1 ? (
                                                            <span aria-hidden="true" className=" dds__mx-2"> | </span>
                                                        ) : (
                                                            ""
                                                        ); // Add separator only if it's not the last value
                                                    return (
                                                        <React.Fragment key={record[col.accessor]}>
                                                            <CellComponent
                                                                value={col.accessor === "shirCapable" && record[col.accessor] === "False" ? "SHIR - False" :
                                                                    col.accessor === "shirCapable" && record[col.accessor] === "True" ? "SHIR - True" : value}
                                                                row={record} />
                                                            {separator}
                                                        </React.Fragment>
                                                    );
                                                })}
                                        </div>
                                    </AccordianHeadingWrapper>
                                    <AccordianBodyWrapper
                                        key={recordIndex}
                                        contentId={contentId}
                                        triggerId={triggerId}
                                    >
                                        <div

                                            className="dds__border dds__p-4"
                                            style={{
                                                backgroundColor: "var(--gray-100)",
                                            }}
                                        >
                                            {sideWayTable && <table className="dds__w-100">
                                                <tbody className="dds__container">
                                                    {bodyColumns
                                                        .map(col => (
                                                            <tr key={col.accessor} className="dds__row">
                                                                <th id={col.accessor} scope="row" key={col.Header} className="dds__col--2 dds__col--md-4" style={{ color: "#0063B8", textAlign: "left" }}>
                                                                    {col.Header}
                                                                </th>
                                                                <td headers={col.accessor} key={record[col.accessor]} className="dds__col--2 dds__col--md-8">
                                                                    {/* <p className="dds__body-2" style={{ wordBreak: "break-all", color: "#0E0E0E" }}>
                                                                        {<col.Cell value={record[col.accessor]} row={record} />}
                                                                    </p> */}
                                                                    <CellComponent content={record[col.accessor]} />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>}
                                            {!sideWayTable && (
                                                <>
                                                    {onlyShowDomainManagerServiceAccounts &&
                                                        <div style={{ marginLeft: "10px" }}>
                                                            <tr className="dds__row">
                                                                <th scope="row" className="dds__col--2 dds__col--md-4" style={{ color: "#0063B8", textAlign: "left" }}>
                                                                    Domain
                                                                </th>
                                                                <td className="dds__col--2 dds__col--md-8" style={{ paddingLeft: "30px" }}>
                                                                    <p>{record["domain"]}</p>
                                                                </td>
                                                            </tr>
                                                            <tr className="dds__row">
                                                                <th scope="row" className="dds__col--2 dds__col--md-4" style={{ color: "#0063B8", textAlign: "left" }}>
                                                                    {enableExportButton ? "Owner:" : "Manager"}
                                                                </th>
                                                                <td className="dds__col--2 dds__col--md-8" style={{ paddingLeft: "30px" }}>
                                                                    <p>{record[enableExportButton ? "owner" : "manager"]}</p>
                                                                </td>
                                                            </tr>
                                                        </div>}


                                                    <CardTableStyle>
                                                        <thead>
                                                            <tr>
                                                                {bodyColumns
                                                                    .map(col => (
                                                                        <th id={col.accessor} scope="row" key={col.accessor} style={{ color: "#0063B8" }}>
                                                                            {col.Header}
                                                                        </th>
                                                                    ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                {bodyColumns
                                                                    .map(col => (

                                                                        <td headers={col.accessor} key={col.accessor} data-label={col.Header}>
                                                                            <p className="dds__body-2" style={{ wordBreak: "break-all", color: "#0E0E0E" }}>
                                                                                {<col.Cell value={record[col.accessor]} row={record} />}
                                                                            </p>
                                                                        </td>
                                                                    ))}
                                                            </tr>
                                                        </tbody>
                                                    </CardTableStyle></>)
                                            }
                                        </div>
                                    </AccordianBodyWrapper>

                                </div>
                            )
                        })}
                        {/* Pagination Component */}
                        {filteredData.length > 10 && (
                            <Pagination
                                totalPages={pageCount}
                                pageIndex={pageIndex}
                                gotoPage={setPageIndex}
                                canPreviousPage={canPreviousPage}
                                canNextPage={canNextPage}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                pageCount={pageCount}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                compactPagination={false}
                            />
                        )}

                        {filteredData?.length === 0 && <p>No Matching Record Found</p>}
                    </AccordianWrapper>
                </>
            )}
        </div>
    );
};

export default DDSAccordian;
