import React, { useState } from 'react';
import LoadingSpinner from '../../../Common/LoadingSpinner';
import CardError from '../CardError';
import CardText from '../../CardText.tsx/CardText';
import { NumberWrapper } from '../../CardText.tsx/Styles';
import DDSAccordian from '../../Accordian/DDSAccordian'; // Assuming this component exists
import { createAccordianID } from '../Asset Request Tracker Card/User'; // Assuming this function exists
import { cardColumns, serviceaccounts } from '../../constants';
import { useDashboardContext } from '../../../../store/dashboard/DashboardContext';
import useServiceAccountOrgData from './useServiceAccountOrgData';
import NoRecordFound from '../../CardTable/NoRecordFound';

//This Component is used to render the My Org Accounts tab in the Service Accounts Card. It Contains the list of service accounts under the Leader's Organization.

//Define columns
const columns: cardColumns = [{
    Header: "Account Name",
    accessor: "samaccountName",
    Cell: ({ value }) => <><strong>Account:</strong> <span className="dds__card__header__subtitle" style={{ color: "grey" }}>{value}</span></>,
    searchable: true,
    visible: false,
},
{
    Header: "Domain Name",
    accessor: "domain",
    Cell: ({ value }) => <><strong>Domain:</strong> <span className="dds__card__header__subtitle" style={{ color: "grey" }}>{value}</span></>,
    searchable: true,
    visible: false,//Columns not shown in table but shown as header of Accordion have visible set to false
    hidden: true
},
{
    Header: "Password reset on",
    accessor: "pwdLastSet",
    Cell: ({ value }) => <><strong>Reset on:</strong> <span className="dds__card__header__subtitle" style={{ color: "grey" }}>{value}</span></>,//Showing Header too with data
    searchable: true,
    visible: false,
},
{
    Header: "Password expirees in",
    accessor: "expiry",
    Cell: ({ value }) => <><strong>password expires in:</strong> <span className="dds__card__header__subtitle" style={{ color: "grey" }}>{value}</span></>,
    searchable: true,
    visible: false,
},
{
    Header: "Owner",
    accessor: "owner",
    Cell: ({ value }) => <><strong> Owner:</strong> <span className="dds__card__header__subtitle" style={{ color: "grey" }}>{value}</span></>,
    searchable: true,
    visible: false,
    hidden: true
},
{
    Header: "Groups",
    accessor: "memberOf",
    Cell: ({ value }) => {
        const tags = Array.isArray(value) ? value.map((group) => (
            //If value is array of groups display them in dds tags individually using key groups for unique id
            <div key={group} data-dds="tag" className="dds__tag dds__tag--sm" data-sr-dismiss="dismiss" style={{ marginRight: '5px' }}>
                <button type="button">{group}</button>
            </div>
        )) : (
            //else if value is single render a single jsx div
            <div data-dds="tag" className="dds__tag" data-sr-dismiss="dismiss">
                <button type="button">{value}</button>
            </div>
        );

        return <>{tags}</>// returning not just value but value displayed in tags
    },
    searchable: true,
    visible: true,
},
];

const Manager: React.FC = () => {
    /* const isLoading = false; // Assuming hardcoded data, no loading state needed
    const isError = false; // Assuming no error handling needed */



    const [isExporting, setIsExporting] = useState(false);//State to handle while data is being downloaded


    /*  if (isLoading) return <LoadingSpinner size="lg" />;
     if (isError) return <CardError />;
     if (hardcodedData.length === 0)
         return <CardText total={hardcodedData.length} issueMessage={issueMessage} congratsMessage={congratsMessage} />; */
    const { isOrg, activeTab } = useDashboardContext();
    const { isLoading, error, data, isError } =
        useServiceAccountOrgData((!isOrg || (activeTab.category === 'all' && activeTab.name === serviceaccounts)))


    if (isLoading) return <LoadingSpinner size="lg"></LoadingSpinner>;
    if (error) return <CardError />;

    if (data.length === 0) {
        //const issueMessage = <div>You have <NumberWrapper>{data.length}</NumberWrapper> service account{data.length > 1 ? 's' : ''} </div>;
        //const congratsMessage = <div>It looks like you don’t have any service accounts.</div>;
        //return <CardText total={data.length} issueMessage={issueMessage} congratsMessage={congratsMessage} />;
        return <NoRecordFound />
    }

    const exportToCSV = () => {//Logic to export the data in CSV format will be changed when API is ready as data will be downloaded directly from backend
        setIsExporting(true);

        const csvContent = "data:text/csv;charset=utf-8,"
            + columns.map(column => column.Header).join(",") + "\n"
            + data.map(item =>
                columns.map(column => item[column.accessor]).join(",")
            ).join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "My Organisation's Service Accounts Details.csv");
        document.body.appendChild(link); // Required for FF
        link.click();
        document.body.removeChild(link);
        setIsExporting(false);
    };

    return (
        <DDSAccordian
            totalElements={data.length}
            data={data}
            isError={isError}
            isLoading={isLoading}
            columns={columns}
            accordianID={createAccordianID(serviceaccounts)}
            enableExportButton={true}
            enablePagination={true}
            isExporting={isExporting}
            onClick={exportToCSV}
            sideWayTable={false}
            onlyShowDomainManagerServiceAccounts={true}
        />
    );
}

export default Manager;
