import React from 'react';
import LoadingSpinner from '../../../Common/LoadingSpinner';
import CardError from '../CardError';
import CardText from '../../CardText.tsx/CardText';
import { NumberWrapper } from '../../CardText.tsx/Styles';
import DDSAccordian from '../../Accordian/DDSAccordian'; // Assuming this component exists
import { createAccordianID } from '../Asset Request Tracker Card/User'; // Assuming this function exists
import { cardColumns, eol, serviceaccounts } from '../../constants';
import { useDashboardContext } from '../../../../store/dashboard/DashboardContext';
import useServiceAccountData from './useServiceAccountData';
import NoRecordFound from '../../CardTable/NoRecordFound';

const columns: cardColumns = [
    {
        Header: "Account Name",
        accessor: "samaccountName",
        Cell: ({ value }) => <><strong>Account:</strong> <span className="dds__card__header__subtitle" style={{ color: "grey" }}>{value}</span></>,
        searchable: true,
        visible: false,
    },
    {
        Header: "Domain Name",
        accessor: "domain",
        Cell: ({ value }) => <><strong>Domain:</strong> <span className="dds__card__header__subtitle" style={{ color: "grey" }}>{value}</span></>,
        searchable: true,
        visible: false,
        hidden: true
    },
    {
        Header: "Password reset on",
        accessor: "pwdLastSet",
        Cell: ({ value }) => <><strong>Reset on:</strong> <span className="dds__card__header__subtitle" style={{ color: "grey" }}>{value}</span></>,
        searchable: true,
        visible: false,
    },
    {
        Header: "Password expirees in",
        accessor: "expiry",
        Cell: ({ value }) => <><strong>Password expiry:</strong> <span className="dds__card__header__subtitle" style={{ color: "grey" }}>{value}</span></>,
        searchable: true,
        visible: false,
    },
    {
        Header: "Manager",
        accessor: "manager",
        Cell: ({ value }) => <><strong>Manager:</strong> <span className="dds__card__header__subtitle" style={{ color: "grey" }}>{value}</span></>,
        searchable: true,
        visible: false,
        hidden: true
    },
    {
        Header: "Groups",
        accessor: "memberOf",
        Cell: ({ value }) => {
            const tags = Array.isArray(value) ? value.map((group) => (
                //If value is array of groups display them in dds tags individually using key groups for unique id
                <div key={group} data-dds="tag" className="dds__tag dds__tag--sm" data-sr-dismiss="dismiss" style={{ marginRight: '5px' }}>
                    <button type="button">{group}</button>
                </div>
            )) : (
                //else if value is single render a single jsx div
                <div data-dds="tag" className="dds__tag" data-sr-dismiss="dismiss">
                    <button type="button">{value}</button>
                </div>
            );

            return <>{tags}</>// returning not just value but value displayed in tags
        },
        searchable: true,
        visible: true,
    },
];

const User: React.FC = () => {
    /*  const isLoading = false; // Assuming hardcoded data, no loading state needed
     const isError = false; // Assuming no error handling needed
 
     const issueMessage = <div>You have <NumberWrapper>{hardcodedData.length}</NumberWrapper> service accounts{hardcodedData.length > 1 ? 's' : ''}</div>;
     const congratsMessage = <div>You don't have any service accounts</div>;
 
     if (isLoading) return <LoadingSpinner size="lg" />;
     if (isError) return <CardError />;
     if (hardcodedData.length === 0)
         return <CardText total={hardcodedData.length} issueMessage={issueMessage} congratsMessage={congratsMessage} />; */

    const { isOrg, activeTab } = useDashboardContext();
    const { isLoading, error, data, isError } =
        useServiceAccountData((!isOrg || (activeTab.category === 'all' && activeTab.name === serviceaccounts)))

    if (isLoading) return <LoadingSpinner size="lg"></LoadingSpinner>;
    if (error) return <CardError />;

    if (data.length === 0)
        return <NoRecordFound />

    return (
        <DDSAccordian
            totalElements={data.length}
            data={data}
            isError={isError}
            isLoading={isLoading}
            columns={columns}
            accordianID={createAccordianID(serviceaccounts)}
            enableSearch={false}
            sideWayTable={false}
            onlyShowDomainManagerServiceAccounts={true}
        />
    );
}

export default User;