//Pagination Component for Accordion similar to Server Table Pagination Component
import React from "react";
import { ReactComponent as FirstPageIcon } from "../../../Assets/First page.svg";
import { ReactComponent as LastPageIcon } from "../../../Assets/Last page.svg";
import { ReactComponent as NextPageIcon } from "../../../Assets/Next Page.svg";
import { ReactComponent as PrevPageIcon } from "../../../Assets/Prev Page.svg";
import "./paginationcss.css";
import { IconButton } from "../../Table/TableStyles";

export const Pagination: React.FC<{
	totalPages: number;
	pageIndex: number;
	gotoPage: (page: number) => void;
	canPreviousPage: boolean;
	canNextPage: boolean;
	previousPage: () => void;
	nextPage: () => void;
	pageCount: number;
	pageSize: number;
	setPageSize: (size: number) => void;
	compactPagination: boolean;
}> = ({
	totalPages,
	pageIndex,
	gotoPage,
	canPreviousPage,
	canNextPage,
	previousPage,
	nextPage,
	pageCount,
	pageSize,
	setPageSize,
	compactPagination,
}) => {
		return (totalPages > 1 || pageSize > 10) ? (
			<nav className="dds__d-flex dds__flex-wrap dds__justify-content-lg-between dds__justify-content-around" role="navigation" aria-label="Pagination Navigation" style={{ padding: '10px' }}>
				<div className="dds__d-flex dds__flex-wrap" >
					{/* <span>
							Go to page:{' '}
							<input
								type="number"
								defaultValue={pageIndex + 1}
								onChange={e => {
									const page = e.target.value ? Number(e.target.value) - 1 : 0
									gotoPage(page)
								}}
								style={{ width: '100px' }}
								min={1}
								max={totalPages}
							/>
						</span>{' '} */}

					<span className="dds__mr-2 dds__align-self-center">
						Page{" "}
						<strong>
							{pageIndex + 1} of {totalPages}
						</strong>{" "}
					</span>

					{!compactPagination && (
						<select
							value={pageSize}
							onChange={e => {
								setPageSize(Number(e.target.value));
							}}
						>
							{[10, 20, 30].map(pageSize => (
								<option
									className="dds__input-text"
									key={pageSize}
									value={pageSize}
									
								>
									Show {pageSize}
								</option>
							))}
						</select>
					)}
				</div>



				<div>
					<IconButton
						className="dds__mx-3"
						onClick={() => gotoPage(0)}
						disabled={!canPreviousPage}
						style={{ marginBottom: '0.5%' }}
					>
						<FirstPageIcon
							style={{ width: "80%" }}
							stroke={!canPreviousPage ? "lightgray" : ""}
						/>
					</IconButton>
					<IconButton
						className="dds__mx-3"
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
					>
						<PrevPageIcon
							style={{ width: "80%" }}
							stroke={!canPreviousPage ? "lightgray" : ""}
						/>
					</IconButton>
					<IconButton
						className="dds__mx-3"
						onClick={() => nextPage()}
						disabled={!canNextPage}
					>
						<NextPageIcon
							style={{ width: "80%" }}
							stroke={!canNextPage ? "lightgray" : ""}
						/>
					</IconButton>
					<IconButton
						className="dds__mx-3"
						onClick={() => gotoPage(pageCount - 1)}
						disabled={!canNextPage}
					>
						<LastPageIcon
							style={{ width: "80%" }}
							stroke={!canNextPage ? "lightgray" : ""}
						/>
					</IconButton>
				</div>
			</nav>
		) : (
			<></>
		);
	};
