import { useCustomQuery } from "../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../react-query/constants";
import { fetchServiceAccountUserConfig, fetchUnauthConfig } from "../API";

const useServiceAccountData = (enabled: boolean = true) => {
	return useCustomQuery(
		[queryKeys.serviceaccountuser],
		fetchServiceAccountUserConfig(),
		{
			//Using unauth api for getting the user's role for now change it with service accounts api
			select: data => data.data,
		}
	);
};

export default useServiceAccountData;
