import React, { useEffect, useState } from 'react'
import { useUserInfo } from '../../../../store/auth/UserInfoContext';
import DDSTabs, { TabsData } from '../../../Common/Tabs/DDSTabs';
import Card from '../../Card/Card';
import { createTrigger, TriggerAction } from '../../Common/TriggerAction';
import { cardsTheme, unauth, serviceaccounts } from '../../constants';
import DDSDrawer from '../../Drawer/DDSDrawer';
import { userTab } from '../../DashboardPage/useMetaAPI';
import User from './Users';
import Manager from './Manager';
import { useserviceaccountsAPI } from './serviceaccountsapi';
import { useDashboardContext } from '../../../../store/dashboard/DashboardContext';
import { useDashboardAPIs } from '../../DashboardPage/useDashboardAPIs';
import useServiceAccountData from './useServiceAccountData';
import useServiceAccountOrgData from './useServiceAccountOrgData';

const ServiceAccountsCard: React.FC<{
	favouriteStatus: boolean, description: any, tabsMetaData: userTab, actionRequired: boolean
}> = ({
	favouriteStatus,
	description,
	tabsMetaData,
	actionRequired
}) => {
		const { isLoading: isUserLoading, error: userError, data: userData } = useServiceAccountData();
		const { isLoading: isLeaderLoading, data: leaderData, error: leaderError } = useServiceAccountOrgData();
		const { getUserRole } = useUserInfo();
		const role = getUserRole(serviceaccounts);//For now getting role as user or leader or admin or superadmin from unauth API
		const { isOrg } = useDashboardContext();

		const { data: metaData } = useDashboardAPIs();
		const [message, setMessage] = useState("View Details")

		useEffect(() => {
			if (!isOrg) {
				if (metaData && metaData.data[serviceaccounts]) {
					setMessage(metaData.data[serviceaccounts]);
				}
			}
		}, [metaData, isOrg]);

		useEffect(() => {
			if (isOrg && !isLeaderLoading && !leaderError) {
				let temp = "No Service Accounts found"
				if (leaderData.length > 0)
					temp = userData.length + ' Active Accounts';
				setMessage(temp);
			} else
				if (!isOrg && !isUserLoading && !userError) {
					let temp = "No Service Accounts found"
					if (userData.length > 0)
						temp = userData.length + ' Active Accounts';
					setMessage(temp);
					// Add logic to update query data with message 
				}
		}, [isUserLoading, userData, userError, isOrg, isLeaderLoading, leaderData, leaderError]);

		const tabDetails: TabsData = [{
			hidden: !tabsMetaData.user || !role.user,//User Tab shown only if user role is user
			name: tabsMetaData.user,
			iconClass: "dds__icon--user",
			content: User,
			active: !isOrg,
			footerButtons: []
		},
		{
			hidden: !tabsMetaData.user || !role.leader,//Leader tab will also be shown if user role is leader for now role is set to user only for this too as API is not ready and  UI design was being modified
			name: "My Org Accounts",
			iconClass: "dds__icon--user",
			content: Manager,
			active: !isOrg,
			footerButtons: []
		}]
		const triggerID = createTrigger(serviceaccounts)//This trigger sets to open the side Drawer


		return <Card
			themeColor={cardsTheme[serviceaccounts].themeColor}
			name={serviceaccounts}
			ddsIconClass={cardsTheme[serviceaccounts].ddsIconClass}
			isFav={favouriteStatus}
			idName={serviceaccounts}
			description={description}
			isLoading={(message === "View Details" && !userError)}
			actionRequired={actionRequired}
		>
			<TriggerAction triggerID={triggerID}>
				{message}
			</TriggerAction>
			<DDSDrawer triggerID={triggerID} cardName={serviceaccounts} >
				<DDSTabs tabsMetaData={tabsMetaData} idName={serviceaccounts} tabsData={tabDetails} salt={serviceaccounts} />
			</DDSDrawer>
		</Card>

	}

export default ServiceAccountsCard
