export const req_tracker = "Asset Request Tracker";
export const cvl = ["Security Threats on Your Computer", "Security Threats"];
export const unauth = "Software Removal Dashboard";
export const eol = "End of Life Devices";
export const vdi = "VDI Request Management";
export const inventory = "Asset Tracker";
export const quarantine_portal = "Quarantine Portal";
export const passwordReset = "Password Management";
export const mobiledevices = "Mobile Devices";
export const pcversion = "PC Version";
export const serviceaccounts = "Service Accounts";

export const cardsTheme: {
	[key: string]: {
		themeColor: string;
		ddsIconClass: string;
	};
} = {
	[eol]: {
		themeColor: "orange",
		ddsIconClass: "icon--EOL",
	},
	[req_tracker]: {
		themeColor: "green",
		ddsIconClass: "icon--assetTracker",
	},
	[cvl[0]]: {
		themeColor: "yellow",
		ddsIconClass: "dds__icon--lock-open",
	},
	[unauth]: {
		themeColor: "red",
		ddsIconClass: "icon--unauth",
	},
	[vdi]: {
		themeColor: "blue",
		ddsIconClass: "icon--vdi",
	},
	[inventory]: {
		themeColor: "purple",
		ddsIconClass: "dds__icon--device-laptop",
	},
	[passwordReset]: {
		themeColor: "berry",
		ddsIconClass: "dds__icon--lock-closed",
	},
	[mobiledevices]: {
		themeColor: "blue",
		ddsIconClass: "dds__icon--chart-bars-axis",
	},
	[pcversion]: {
		themeColor: "green",
		ddsIconClass: "dds__icon--gear-arrow",
	},
	[quarantine_portal]: {
		themeColor: "green",
		ddsIconClass: "icon--assetTracker",
	},
	[serviceaccounts]: {
		themeColor: "orange",
		ddsIconClass: "dds__icon--gear",
	},
};

export type cardColumns = {
	accessor: string;
	Header: string;
	Cell: ({ value, row }: { value: any; row?: any }) => JSX.Element;
	searchable: boolean;
	visible: boolean;
	hidden?: boolean;
}[];

export type activeType = "all" | "fav" | "org" | "action";

export const breakpointsInfo = {
	"2xl": {
		max: 8024,
		min: 1301,
		maxCardsInRow: 3,
	},
	// xl: {
	// 	max: 1584,
	// 	min: 1366,
	// 	maxCardsInRow: 3,
	// },
	lg: {
		max: 1301,
		min: 1200,
		maxCardsInRow: 2,
	},
	md: {
		max: 1200,
		min: 930,
		maxCardsInRow: 2,
	},
	sm: {
		max: 930,
		min: 768,
		maxCardsInRow: 2,
	},
	xs: {
		max: 768,
		min: 480,
		maxCardsInRow: 1,
	},
	xxs: {
		max: 480,
		min: 1,
		maxCardsInRow: 1,
	},
};
