export const baseUrl = process.env.REACT_APP_BASE_URL;
const eolBaseUrl = process.env.REACT_APP_EOL_BASE_URL;
export const newBaseURL = process.env.REACT_APP_INVENTORY_NEW_BASE_URL;

const fetchAnnouncements = "/api/Fetch/v1/Announcements";
export const fetchAnnouncementsConfig = (): object => ({
	url: baseUrl + fetchAnnouncements,
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

const fetchVDI = "/api/Fetch/v1/VDIDashboard";
export const fetchVDIDashboardConfig = (): object => ({
	url: baseUrl + fetchVDI,
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

const fetchUnauth = "/api/Fetch/v1/UnAuthSoftDashboard";
export const fetchUnauthConfig = (): object => ({
	url: baseUrl + fetchUnauth,
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

const fetchServiceAccountUser = "/serviceAccount/v1/individual/list";
export const fetchServiceAccountUserConfig = (): object => ({
	url: newBaseURL + fetchServiceAccountUser,
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

const fetchServiceAccountOrg = "/serviceAccount/v1/reportee/list";
export const fetchServiceAccountOrgConfig = (): object => ({
	url: newBaseURL + fetchServiceAccountOrg,
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

// export const fetchEolConfig = (): object => ({
// 		url: `${eolBaseUrl}/api/Fetch/v1/EOLInfo`,
// 		method: "GET",
// 		headers: {
// 			"Content-Type": "application/json",
// 		},
// 	});

export const fetchEolOrgConfig = (): object => ({
	url: `${eolBaseUrl}/api/Fetch/v1/EOLORGInfo`,
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

export const fetchEolUserConfig = (): object => ({
	url: `${eolBaseUrl}/api/Fetch/v1/EOLUserInfo`,
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

const pwdManagement = "/api/Fetch/v1/PasswordExpiry";
export const fetchPasswordManagementDataDashboardConfig = (): object => ({
	url: baseUrl + pwdManagement,
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

export const fetchPCVersionDetails = (): object => ({
	url: baseUrl + "/api/Fetch/v1/PCVersion",
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});
